import { useEffect } from "react";

const BlockPage = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#E55972";
  }, []);

  return null;
};

export default BlockPage;
